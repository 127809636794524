<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <div class="more" @click="handleMoreClick">更多>></div>
    </div>
    <div class="list">
      <div class="item" :style="{'--itemWidth' : itemWidth}" v-for="(item, i) in dataList.slice(0,5)" :key="i" @click="handleDetailClick(item.id)">
        <img :src=item.pictureUrl class="itemImg"/>
        <div class="des">
          <div class="item-title h2">{{item.propertyName}}</div>
          <div class="item-des">{{item.productAdvantages}}</div>
          <div class="num h2">{{item.inputBudget}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'knowledgeCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth:'18%',
    }
  },
  computed:{
  },
  mounted() {
    // this.litsDataChange();
  },
  methods: {
    handleDetailClick(id) {
      this.$router.push({path:"/intellectualProperty/knowledgeDetail",  query: {id: id}});
    },
    handleMoreClick() {
      this.$router.push("/intellectualProperty/knowledgeList");
    },
    // litsDataChange() {
    //     let count = this.dataList.length;
    //     if (count >= 5){
    //       this.itemWidth = '18%';
    //     }else if (count == 4){
    //       this.itemWidth = '23%';
    //     }else if (count == 3){
    //       this.itemWidth = '31%';
    //     }else if (count == 2){
    //       this.itemWidth = '48%';
    //     }else if (count == 1){
    //       this.itemWidth = '100%';
    //     }
    //
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  padding: 0 10px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
  }
  .list {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    .item {
      cursor: pointer;
      width: var(--itemWidth);
      padding: 10px;
      box-sizing: border-box;
      height: 300px;
      background: white;
      text-align: center;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      .itemImg{
        width: 100%;
        height: 160px;
      }
      .des{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        width: 100%;
        height: 140px;
        .item-title{
          font-family: "Arial Black";
          color: black;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item-des{
          color: grey;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .num{
          color: red;
        }
      }
    }
  }
}
</style>
