<template>
  <div class="body-content">
    <banner v-if="bannerList&&bannerList.length>0" :homeBanner="bannerList"></banner>
    <!-- <div class="long-search-wrapper">
      <div class="input-wrapper">
        <el-input placeholder="请输入搜索关键字" style="border: none;" class="h2" v-model="searchKey"></el-input>
      </div>
      <div class="search-button-wrapper" @click="router2List('search')">
        <img
          src="@/static/images/big-search.png"
          style="width: 30px;height: 30px;margin: 7px 43px;"
        />
      </div>
    </div>-->
    <div class="wd100 pt10 secondRow">
      <div class="secondLeft bg-rgba-w-1">
        <div class="title wd100">
          <div class="left light-blue zc-title">动态政策</div>
          <div class="more" @click="router2List('notice')">更多>></div>
        </div>
        <br />
        <div
          class="item-wrapper"
          v-for="item of policyList"
          :key="item.id"
          @click="handleNoticeDetailClick(item)"
        >
          <div class="title" style="text-align: left;">{{item.title}}</div>
          <div class="time">{{item.time?item.time.substring(0,10):'--'}}</div>
        </div>
      </div>
      <div class="secondRight">
        <button class="findBut" @click="router2List('patentList')">找专利</button>
        <button class="findBut" @click="router2List('safeguard')">维权援助</button>
      </div>
    </div>
    <flow-card-tab title="专利交易大厅"></flow-card-tab>
    <!--交易资料-->
    <div class="businesses_data bg-rgba-w-1 pt20 wt100">
      <div class="titleCard">
        <label class="h1 liable">交易资料</label>
        <label class="h5 liable">/方便快捷，节约您的时间</label>
      </div>
      <div class="flowList">
        <div class="cardRow">
          <img src="../../static/images/icon_business/6.png" height="131" width="593" />
          <img src="../../static/images/icon_business/7.png" height="131" width="593" />
        </div>
        <div class="cardRow">
          <img src="../../static/images/icon_business/8.png" height="131" width="593" />
          <img src="../../static/images/icon_business/9.png" height="131" width="593" />
        </div>
      </div>
    </div>
    <knowledge-card-tab title="知识产权大厅" :dataList="propertyInfoList"></knowledge-card-tab>
    <table-tab title="专利交易动态" :dataList="tradeList"></table-tab>
    <specialist-card-tab title="专家人才" :dataList="expertList"></specialist-card-tab>
    <organization-card-tab title="合作机构" :dataList="gizList"></organization-card-tab>
  </div>
</template>

<script>
import Banner from '../../components/banner.vue';
import longSearch from '@/components/long-search.vue'
import flowCardTab from './components/flowCardTab.vue'
import knowledgeCardTab from './components/knowledgeCardTab.vue'
import tableTab from './components/tableTab.vue';
import specialistCardTab from './components/specialistCardTab.vue'
import organizationCardTab from './components/organizationCardTab.vue'

export default {
  name: 'knowledge-index',
  components: {
    Banner,
    longSearch,
    flowCardTab,
    knowledgeCardTab,
    tableTab,
    specialistCardTab,
    organizationCardTab
  },
  data() {
    return {
      pageTopPosition: 0,
      searchKey: '',
      bannerList: [],
      //专家
      expertList: [],
      //合作机构
      gizList: [],
      //政策
      policyList: [],
      //知识产权大厅
      propertyInfoList: [],
      //专利交易
      tradeList: [],
      imgHeight: "",
    }
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  created() {
    // 调用工具方法示例
    console.log(this.$util.testName());
  },
  mounted() {
    this.getIndexInfo()
  },
  methods: {
    handleNoticeDetailClick(item) {
      this.$router.push({ path: "/intellectualProperty/noticeDetail", query: { id: item.id } });
    },
    getIndexInfo() {
      this.$api.intellectualProperty.index().then((res) => {
        const data = res.data.data
        if (data) {
          this.bannerList = data.bannerList
          this.expertList = data.expertList
          this.gizList = data.gizList
          this.policyList = data.policyList
          if (this.policyList.length > 5) {
            this.policyList = this.policyList.splice(0, 5)
          }
          this.propertyInfoList = data.propertyInfoList
          this.tradeList = data.tradeList
        }
      })
    },
    router2List(where) {
      this.$router.push("/intellectualProperty/" + where);
    },
    search(v) {
      console.log('search key = ', v)
    },
    imgLoad() {
      // 获取图片高度
      this.$nextTick(() => {
        this.imgHeight = this.$refs.imgHeight.height + 'px'
        console.log(this.imgHeight)
      })
    },
  }
}
</script>

<style scoped lang="less">
/deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
/deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}
/deep/.el-carousel--horizontal {
  overflow-x: hidden;
  border-radius: 20px;
}

.long-search-wrapper {
  margin: 40px 0;
  min-height: 45px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #808080;
  background-color: #fff;

  .input-wrapper {
    flex: 1;
    /deep/.el-input__inner {
      border: none;
    }
    /deep/.el-input__inner:focus {
      border: none;
    }
  }

  .search-button-wrapper {
    min-width: 50px;
    background-color: #f39800;
    cursor: pointer;
  }
}

.businesses_data {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  height: 380px;
  .titleCard {
    height: 30px;
    width: 100%;
    align-items: center;
    padding-left: 10px;
  }
  .flowList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    .cardRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      .liable {
        margin-top: 20px;
      }
    }
  }
}

.secondRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 210px;
  margin: auto;
  .secondLeft {
    height: 100%;
    width: 70%;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      padding-left: 20px;
      font-family: MicrosoftYaHei;
      // font-size: 12px;
      .zc-title {
        font-family: MicrosoftYaHei;
        font-size: 20px;
      }
      .more {
        min-width: 70px;
        cursor: pointer;
        padding-right: 30px;
        cursor: pointer;
      }
    }
    .item-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      height: 28px;
      // font-size: 14px;
      .title {
        width: 80%;
        display: block;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .time {
        display: block;
        height: 100%;
        flex: 1;
        min-width: 130px;
      }
    }
  }
  .secondRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 29%;
    background-size: cover;
    background-image: url("../../static/images/intellectualBack1.png");
    .findBut {
      cursor: pointer;
      border: 0;
      height: 39px;
      width: 163px;
      background-color: rgba(255, 255, 255, 0);
      background-size: cover;
      background-image: url("../../static/images/buttom_intellectual/but_bak_orage.png");
    }
  }
}
</style>
