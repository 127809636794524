<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <!--      <div class="more"  @click="handleMoreClick">更多>></div>-->
    </div>
    <div class="list bg-rgba-w-1">
      <div style="padding-left: 20px" class="page-spe-prev">
        <img src="../../../static/images/left.png" height="69" width="41" style="cursor: pointer;" />
      </div>
      <div class="swiper-container swiper-container-spe">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="i in Math.ceil(dataList.length/6)" :key="'page'+i">
            <div class="cardRow">
              <div
                class="specialistInfo"
                v-for="(item,j) in dataList.slice((i-1)*6,i*6-3)"
                :key="item.id"
                @click="handleDetailClick(item.id)"
              >
                <img :src="item.pictureUrl" height="67" width="67" />
                <div class="textInfo">
                  <div class="specialist_name">{{item.expertName}}</div>
                  <div class="specialist_icon">{{expertLevelDic[item.expertLevel]}}</div>
                  <div class="specialist_liable">领域：{{fieldDic[item.field]}}</div>
                  <div class="specialist_liable">单位：{{item.unitName}}</div>
                </div>
              </div>
            </div>
            <div class="cardRow">
              <div
                class="specialistInfo"
                v-for="(item,j) in dataList.slice((i-1)*6 + 3,i*6)"
                :key="item.id"
                @click="handleDetailClick(item.id)"
              >
                <img :src="item.pictureUrl" height="67" width="67" />
                <div class="textInfo">
                  <div class="specialist_name">{{item.expertName}}</div>
                  <div class="specialist_icon">{{expertLevelDic[item.expertLevel]}}</div>
                  <div class="specialist_liable">领域：{{fieldDic[item.field]}}</div>
                  <div class="specialist_liable">单位：{{item.unitName}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-right: 20px" class="page-spe-next">
        <img src="../../../static/images/right.png" height="69" width="41" style="cursor: pointer;" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  name: 'specialistCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth: 0,
      fieldDic: {
        1: "农林/牧渔/食品", 2: "交通/运输/包装", 3: "化工/冶金/生物", 4: "纺织/造纸", 5: "建筑/采矿/照明", 6: "机械/加工/设备", 7: "服饰/日用/家电", 8: '通信/电子/数码',
        9: '医疗/保健/美容',
        10: '能源/动力/电力',
        11: '法律领域',
      },
      expertLevelDic: { 1: "高级知识产权师" }
    }
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let s = new Swiper('.swiper-container-spe', {
        paginationClickable: true,
        speed: 1000,
        loop: true,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        autoplay: 1500,
        navigation: {
          nextEl: '.page-spe-next',
          prevEl: '.page-spe-prev'
        }

      })
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/intellectualProperty/specialDetail", query: { id: id } });
    },
    handleMoreClick() {
      this.$router.push("/intellectualProperty/specialList");
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  padding: 0 0px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
  }
  .list {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 320px;
    align-items: center;
    .swiper-container-spe {
      width: 90%;
      height: 320px;
      .swiper-slide {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .cardRow {
          height: 128px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-around;
          margin: 10px 10px 0 10px;
          .specialistInfo {
            cursor: pointer;
            width: 31%;
            background: white;
            height: 128px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .textInfo {
              flex: 0.75;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: space-around;
              color: black;

              .specialist_name {
                font-size: 16px;
                font-family: "Arial Black";
              }

              .specialist_icon {
                color: blue;
                font-size: 14px;
                background-color: #d6e3e5;
                margin-bottom: 10px;
              }

              .specialist_liable {
                color: grey;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
