<template>
  <div class="pic-list-block">
    <div class="title">
      <div class="left light-blue h1">{{title}}</div>
      <!--      <div class="more"  @click="handleMoreClick">更多>></div>-->
    </div>
    <div class="list bg-rgba-w-1">
      <div style="padding-left: 20px" class="page-prev">
        <img src="../../../static/images/left.png" height="69" width="41" style="cursor: pointer;" />
      </div>
      <div class="swiper-container swiper-container-giz">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="i in Math.ceil(dataList.length/10)" :key="'page'+i">
            <div class="cardRow">
              <div
                class="specialistInfo"
                v-for="(item,j) in dataList.slice((i-1)*10, i*10 - 5)"
                :key="item.id"
              >
                <img
                  :src="item.pictureUrl"
                  height="100%"
                  @click="handleDetailClick(item.id)"
                  style="cursor: pointer;"
                />
              </div>
            </div>
            <div class="cardRow">
              <div
                class="specialistInfo"
                v-for="(item,j) in dataList.slice((i-1)*10 + 5,i*10)"
                :key="item.id"
              >
                <img
                  :src="item.pictureUrl"
                  height="100%"
                  @click="handleDetailClick(item.id)"
                  style="cursor: pointer;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-right: 20px" class="page-next">
        <img src="../../../static/images/right.png" height="69" width="41" style="cursor: pointer;" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'organizationCardTab',
  props: {
    type: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: 0,
      itemWidth: 0,
    }
  },
  computed: {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let s = new Swiper('.swiper-container-giz', {
        paginationClickable: true,
        speed: 1000,
        loop: true,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        autoplay: 1500,
        navigation: {
          nextEl: '.page-next',
          prevEl: '.page-prev'
        }

      })
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/intellectualProperty/organizationDetail", query: { id: id } });
    },
    handleMoreClick() {
      this.$router.push("/intellectualProperty/organizationList");
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pic-list-block {
  margin-top: 40px;
  padding: 0 0px;
  .title {
    display: flex;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
  }
  .list {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 320px;
    align-items: center;
    .swiper-container-giz {
      width: 90%;
      height: 320px;
      .swiper-slide {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .cardRow {
          margin: 10px 20px 0 20px;
          height: 35%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          .specialistInfo {
            width: 19%;
            flex: 0;
            background: white;
            height: 100px;
            align-items: start;
            justify-content: space-around;
          }
        }
      }
    }
  }
}
</style>
