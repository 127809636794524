<template>
	<!-- 首页，最近政策列表 -->
	<div class="long-search-wrapper">
		<div class="input-wrapper">
			<el-input placeholder="请输入搜索关键字" style="border: none;" class="h2" v-model="searchKey"></el-input>
		</div>
		<div class="search-button-wrapper" @click="search">
			<img src="@/static/images/big-search.png" style="width: 30px;height: 30px;margin: 7px 43px;" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchKey:''
			}
		},
		// maxCount:最多显示的条数
		props: ['title', 'data', 'maxCount'],
		mounted() {

		},
		methods: {
			search() {
				if (!this.searchKey||this.searchKey.trim()==''){
					this.$message.warning('请输入关键字')
					return 
				}
				this.$emit('search',this.searchKey)
			},
		}
	}
</script>
<style scoped lang="less">
	.long-search-wrapper {
		min-height: 45px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid #808080;
		background-color: #fff;
		
		.input-wrapper {
			flex: 1;
		}

		.search-button-wrapper {
			min-width: 50px;
			background-color: #F39800;
			cursor: pointer;
		}
	}
	/deep/.el-input__inner{
		border: none;
	}
	/deep/.el-input__inner:focus{
		border:none;
	}
</style>
